
import { defineComponent, onMounted, ref } from 'vue'

export default defineComponent({
  setup () {
    const imageList = [
      require('@/assets/image/swiper/swiper-1.jpg'),
      require('@/assets/image/swiper/swiper-2.jpg'),
      require('@/assets/image/swiper/swiper-3.jpg'),
      require('@/assets/image/swiper/swiper-4.jpg')
    ]

    const caseList = [{
      info: '个性化企业官网',
      imgUrl: require('@/assets/image/product/product-1.jpg')
    },
    {
      info: '企业办公一体化',
      imgUrl: require('@/assets/image/product/product-2.jpg')
    },
    {
      info: '库存资产条理性',
      imgUrl: require('@/assets/image/product/product-3.jpg')
    },
    {
      info: '定制化电子商务',
      imgUrl: require('@/assets/image/product/product-4.jpg')
    },
    {
      info: '企业宣传公众号',
      imgUrl: require('@/assets/image/product/product-5.jpg')
    },
    {
      info: '专业定制小程序',
      imgUrl: require('@/assets/image/product/product-6.jpg')
    }]

    const advantages = [{
      info: '专业',
      imgUrl: require('@/assets/image/advantages/advantages-1.png')
    }, {
      info: '放心',
      imgUrl: require('@/assets/image/advantages/advantages-2.png')
    }, {
      info: '保障',
      imgUrl: require('@/assets/image/advantages/advantages-3.png')
    }]

    const imgShow = ref(false)

    onMounted(() => {
      imgShow.value = true
    })

    return {
      imageList,
      imgShow,
      caseList,
      advantages
    }
  }
})
